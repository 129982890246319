<template>
  <my-progress-graph :ranged-dataset="rangedDataset" :filters="filters" :header-size="185">
    <template v-slot:filters>
      <my-progress-savings-filters v-if="oldestCfMonth" :filters="filters" :oldest-cf-month="oldestCfMonth"
                                   @filters-changed="filtersChanged"/>
    </template>
    <template v-slot:header>
      <my-progress-savings-header v-if="oldestCfMonth" :ranged-dataset="rangedDataset" :oldest-cf-month="oldestCfMonth"
                                  :filters="filters" @filters-changed="filtersChanged"/>
    </template>
    <template v-slot:graph="graphSlotProps">
      <div class="graph-labels">
        <share-button filename="my-savings-progress" share-name="MySavingsProgress"
                      :share-component="shareComponent"
                      :share-component-props="getShareComponentProps(graphSlotProps)"
                      :segment-extra-props="{ filters }" v-slot="{ onClick }">
          <icon class="share-icon" icon-name="share" fill weight="bold" tabindex="0" @click="onClick"
                @keyup.enter="onClick"/>
        </share-button>
      </div>
      <bar-graph-v2 v-bind="getBarGraphProps(graphSlotProps)" />
    </template>

    <div class="padding-m margin-top-m" v-if="this.savingSteps.length > 0">
      <separator/>
      <div class="ri-bold-title margin-top-m">הצעדים הבאים שלך</div>
      <component v-for="step in savingSteps" :key="step.actionKey" :is="step.cardComponent" class="margin-top-m"/>
    </div>
  </my-progress-graph>
</template>

<script>
import Segment from '@/Segment';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import BarGraphV2 from '@/base-components/bar-graph-v2/BarGraphV2';
import { mapActions, mapGetters, mapState } from 'vuex';
import dateUtils from '@/utils/dates';
import * as HamsterApi from '@/api/HamsterApi';
import moment from 'moment';
import ShareButton from '@/base-components/share/ShareButton';
import {
  ActionKeys, actionKeyToComponent, progressStatusToActionKeys,
} from '@/pages/responsive-pages/authenticated/navigation-pages/my-progress/next-steps/my-progress-next-steps.consts';
import { DATE_FILTERS_STRINGS, SAVINGS_DATA_SECTION_FILTERS_STRINGS } from '../my-progress-filters.consts';
import { cashflowSavings, excludedSavings, getSavingsSum } from './my-progress-saving.utils';
import MyProgressSavingsHeader from './MyProgressSavingsHeader.vue';
import MyProgressSavingsFilters from './MyProgressSavingsFilters.vue';
import MyProgressGraph from '../MyProgressGraph';
import MyProgressShareAsset from '../MyProgressShareAsset';

export default {
  name: 'MyProgressSavingsSummary',
  components: {
    MyProgressGraph,
    BarGraphV2,
    MyProgressSavingsFilters,
    MyProgressSavingsHeader,
    ShareButton,
    Icon: BaseUI.Icon,
    Separator: BaseUI.Separator,
  },
  data() {
    return {
      dataset: null,
      filters: {
        dataSections: {
          ...SAVINGS_DATA_SECTION_FILTERS_STRINGS.cashflowsAndExcluded,
          excluded: true,
          cashflows: true,
        },
        dates: {
          filterKey: 'last-12-months',
          ...DATE_FILTERS_STRINGS['last-12-months'],
          start: null,
          end: null,
        },
      },
      barStyles: {
        backgroundColor: BaseUI.Colors.riseupOrange,
        width: '12px',
        borderRadius: '10px',
      },
      shareComponent: MyProgressShareAsset,
    };
  },
  async created() {
    Segment.trackUserGot('MyProgress_Savings_Graph_Shown');
    this.dataset = await HamsterApi.getRiseupPool();
    const newestCashflowMonth = this.dataset[this.dataset.length - 1].cashflowMonth;
    this.filters.dates.end = newestCashflowMonth;
    this.filters.dates.start = dateUtils.formatCashflowDate(moment(newestCashflowMonth).subtract(11, 'month'));
    this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BEIGE,
      textColor: BaseUI.Colors.riseupDarkGreen,
      logoColor: BaseUI.Colors.riseupDarkGreen,
    });
    Promise.all([this.initCustomerProgress(), this.initAdvisory()]).catch();
  },
  computed: {
    ...mapState('customerProgress', ['customerProgress']),
    ...mapGetters('advisory', ['eligibleForAdvisory']),
    rangedDataset() {
      const monthsInRange = moment(this.filters.dates.end).diff(this.filters.dates.start, 'months') + 1;
      const cashflowMonths = _.times(monthsInRange, i => dateUtils.formatCashflowDate(moment(this.filters.dates.start).add(i, 'months')));
      return cashflowMonths.map(month => this.dataset.find(({ cashflowMonth }) => cashflowMonth === month) || { cashflowMonth: month });
    },
    oldestCfMonth() {
      return _.minBy(this.dataset, ({ cashflowMonth }) => cashflowMonth)?.cashflowMonth;
    },
    possibleSteps() {
      return [ActionKeys.SHORT_TERM_SAVING,
        ActionKeys.LONG_TERM_SAVING,
        ActionKeys.BALANCE_MONEY_CALCULATOR,
        this.eligibleForAdvisory && ActionKeys.ADVISORY];
    },
    progressStatus() {
      return this.customerProgress?.progressState?.progressStatus;
    },
    savingSteps() {
      if (!this.progressStatus) {
        return [];
      }

      const customerSteps = progressStatusToActionKeys[this.progressStatus];
      return _.chain(this.possibleSteps)
        .filter(step => customerSteps.includes(step))
        .map(actionKey => {
          return { actionKey, ...actionKeyToComponent[actionKey] };
        }).value();
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('customerProgress', ['initCustomerProgress']),
    ...mapActions('advisory', ['initAdvisory']),
    valueFormatter({ cashflowMonth, ...otherValues }) {
      if (_.isEmpty(otherValues)) {
        return null;
      }
      const {
        excludedSavings,
        oneTimeSavingActualSum,
        savingEnvelopesActualsSum,
      } = otherValues;
      const value = 0
          + (this.filters.dataSections.cashflows ? oneTimeSavingActualSum + savingEnvelopesActualsSum : 0)
          + (this.filters.dataSections.excluded ? Math.abs(excludedSavings) : 0);
      return { value };
    },
    filtersChanged(filters) {
      this.filters = filters;
    },
    getBarGraphProps({ rangedDataset, labelFormatter, xTickFormatter, yTickFormatter }) {
      return {
        rawValues: rangedDataset,
        valueFormatter: this.valueFormatter,
        labelFormatter,
        xTickFormatter,
        yTickFormatter,
        barStyles: this.barStyles,
        barEdgeMarks: false,
      };
    },
    getShareComponentProps(graphSlotProps) {
      return {
        barGraphProps: this.getBarGraphProps(graphSlotProps),
        title: 'החסכונות שלי',
        formattedDatesRange:
            `${dateUtils.getMonthAndShortYear(this.filters.dates.start)} - ${dateUtils.getMonthAndShortYear(this.filters.dates.end)}`,
        sum: getSavingsSum(cashflowSavings(graphSlotProps.rangedDataset), excludedSavings(graphSlotProps.rangedDataset),
          this.filters.dataSections),
        formattedDataSections: this.filters.dataSections.excluded ? 'סה"כ חסכת' : 'סה"כ חסכת בתזרים בלבד',
        activationDateVisible: graphSlotProps.activationDateVisible,
        withBadge: true,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/spacings';

.graph-labels {
  display: flex;
  flex-direction: column;
  margin: $ri-spacing-sm $ri-spacing-xs 0;
  align-items: flex-end;
}

.share-icon {
  cursor: pointer;
}
</style>
