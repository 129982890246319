<template>
  <div class="my-progress-budgets-header">
    <div class="ri-large-body margin-bottom-sm">
      בין החודשים {{ startDate | getMonthAndShortYear }} - {{ endDate | getMonthAndShortYear }} {{ title }}:
    </div>
    <div class="margin-bottom-xs flex-row align-center">
      <div>
        <animated-number class="ri-medium-display" :value="savingsSum" :formatter="formatAmount" />
        <span class="ri-medium-headline">₪</span>
      </div>
      <img class="margin-right-s" src="/images/my-progress/savings-graph.svg"/>
    </div>

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import _ from 'lodash';
import { getCashflowMonthFromMonthsAgo, getCurrentCashflowMonth } from '@riseupil/common-utils';
import { mapState } from 'vuex';
import { cashflowSavings, excludedSavings, getSavingsSum } from './my-progress-saving.utils';

export default {
  name: 'MyProgressSavingsHeader',
  components: {
    AnimatedNumber: BaseUI.AnimatedNumber,
  },
  props: {
    filters: {
      type: Object, // dataSections, dates
      required: true,
    },
    rangedDataset: {
      type: Array,
      required: true,
    },
    oldestCfMonth: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay']),
    title() {
      if (this.filters.dataSections.excluded) {
        return 'חסכת סה״כ';
      }
      return 'חסכת בתזרים בלבד סה״כ';
    },
    startDate() {
      if (this.oldestCfMonth > this.filters.dates.start) {
        return this.oldestCfMonth;
      }
      return this.filters.dates.start;
    },
    endDate() {
      return _.min([this.filters.dates.end, getCashflowMonthFromMonthsAgo(getCurrentCashflowMonth(this.cashflowStartDay), 1)]);
    },
    cashflowSavings() {
      return cashflowSavings(this.rangedDataset);
    },
    excludedSavings() {
      return excludedSavings(this.rangedDataset);
    },
    savingsSum() {
      return getSavingsSum(this.cashflowSavings, this.excludedSavings, this.filters.dataSections);
    },
  },
  methods: {
    formatAmount(value) {
      return `${moneyUtils.formatAmountWithFraction(value, 0)}${value < 0 ? '-' : ''}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/border-radius";
@import "~@riseupil/base-ui/src/scss/typography";

.my-progress-budgets-header {
  width: 100%;
  background: $riseup_beige;
  padding-top: $ri-spacing-sm;
  text-align: right;
  color: $riseup_black;

  .cf-data-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #F5F1E1;
    border-radius: $card-border-radius;
    padding: $ri-spacing-sm;
  }

  img {
    min-height: 54px;
  }
}
</style>
