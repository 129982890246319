<template>
  <div class="filters">
    <action-button class="filter" :text="filters.dates.shortLabel" :override-icon-props="{ iconName: 'calendar_today', fill: true }"
                   background-color="transparent" :show-left-arrow="false" @click="openDateFilters" />
    <action-button class="filter" :text="filters.dataSections.shortLabel" :override-icon-props="{ iconName: 'filter_list' }"
                   background-color="transparent" :show-left-arrow="false" @click="openDataSectionsFilters" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import MyProgressDateFilter from '../MyProgressDateFilter.vue';
import MyProgressDataSectionsFilter from '../MyProgressDataSectionsFilter.vue';
import { SAVINGS_DATA_SECTION_FILTERS_STRINGS } from '../my-progress-filters.consts';

export default {
  name: 'MyProgressSavingsFilters',
  components: {
    ActionButton: BaseUI.ActionButton,
  },
  props: {
    filters: {
      type: Object, // dataSections, dates
      required: true,
    },
    oldestCfMonth: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openDateFilters() {
      this.openModal({
        component: MyProgressDateFilter,
        props: {
          ...this.filters.dates,
          oldestCfMonth: this.oldestCfMonth,
        },
        popupAlignment: 'menu',
        eventHandlers: {
          'filters-changed': newDates => {
            this.$emit('filters-changed', { dates: newDates, dataSections: this.filters.dataSections });
          },
        },
      });
    },
    openDataSectionsFilters() {
      this.openModal({
        component: MyProgressDataSectionsFilter,
        props: {
          filterOptions: [
            {
              ...SAVINGS_DATA_SECTION_FILTERS_STRINGS.cashflowsAndExcluded,
              cashflows: true,
              excluded: true,
            },
            {
              ...SAVINGS_DATA_SECTION_FILTERS_STRINGS.cashflows,
              cashflows: true,
              excluded: false,
            },
          ],
          initialSelection: this.filters.dataSections,
        },
        popupAlignment: 'menu',
        eventHandlers: {
          'filters-changed': newDataSections => {
            this.$emit('filters-changed', { dates: this.filters.dates, dataSections: newDataSections });
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/spacings";

.filters {
  display: flex;
  flex-direction: row;
  column-gap: $ri-spacing-sm;
  align-items: center;

  .filter {
    display: flex;
    flex: 1;
    justify-content: right;
  }
}
</style>
