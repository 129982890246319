import _ from 'lodash';

export const cashflowSavings = rangedDataset => Math.abs(_.sumBy(rangedDataset, ({
  oneTimeSavingActualSum = 0,
  savingEnvelopesActualsSum = 0,
}) => oneTimeSavingActualSum + savingEnvelopesActualsSum));

export const excludedSavings = rangedDataset => Math.abs(_.sumBy(rangedDataset, ({
  excludedSavings = 0,
}) => excludedSavings));

export const getSavingsSum = (cashflowSavings, excludedSavings, filters) => 0
    + (filters.cashflows ? cashflowSavings : 0)
    + (filters.excluded ? excludedSavings : 0);
